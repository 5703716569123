import { JSX } from 'react'
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js'

export const RecruiterSupportBundle = (): JSX.Element => {
  const initialOptions = {
    clientId: process.env.PAYPAL_CLIENT_ID!,
    currency: 'USD',
    intent: 'capture',
  }
  
  return (
    <PayPalScriptProvider options={initialOptions}>
      <PayPalButtons />
    </PayPalScriptProvider>
  )
}
