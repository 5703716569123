export const BarcodeSvg = (props: Record<string, unknown>): JSX.Element => (
  <svg width="100%" height="100%" viewBox="0 0 86 50" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="2" y="0" width="1" height="50"/>
    <rect x="4" y="0" width="1" height="50"/>
    <rect x="10" y="0" width="1" height="50"/>
    <rect x="13" y="0" width="1" height="50"/>
    <rect x="23" y="0" width="1" height="50"/>
    <rect x="25" y="0" width="1" height="50"/>
    <rect x="44" y="0" width="1" height="50"/>
    <rect x="46" y="0" width="1" height="50"/>
    <rect x="48" y="0" width="1" height="50"/>
    <rect x="51" y="0" width="1" height="50"/>
    <rect x="61" y="0" width="1" height="50"/>
    <rect x="64" y="0" width="1" height="50"/>
    <rect x="68" y="0" width="1" height="50"/>
    <rect x="70" y="0" width="1" height="50"/>
    <rect x="73" y="0" width="1" height="50"/>
    <rect x="77" y="0" width="1" height="50"/>
    <rect x="79" y="0" width="1" height="50"/>
    <rect x="83" y="0" width="1" height="50"/>
    <rect x="85" y="0" width="1" height="50"/>
    <rect x="32" y="0" width="1" height="50"/>
    <rect x="6" y="0" width="2" height="50"/>
    <rect x="16" y="0" width="2" height="50"/>
    <rect x="20" y="0" width="2" height="50"/>
    <rect x="54" y="0" width="2" height="50"/>
    <rect x="57" y="0" width="2" height="50"/>
    <rect x="35" y="0" width="2" height="50"/>
    <rect x="38" y="0" width="1" height="50"/>
    <rect x="27" y="0" width="3" height="50"/>
    <rect x="40" y="0" width="3" height="50"/>
  </svg>
)
