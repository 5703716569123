import styled from '@emotion/styled'
import { css } from '@emotion/react'

const button = css({
  color: 'var(--color-bright)',
  fontFamily: 'var(--font-sans)',
  border: 0,
  borderRadius: 20,
  padding: '8px 24px',
  transition: 'background .2s ease, color .2s ease'
})

export const Styled = {
  Wrapper: styled.div({
    width: 600,
    maxWidth: 'var(--layout-max-width)',
    margin: '0 auto',
    textAlign: 'center',
  
    hr: {
      border: 0,
      
      '&:after': {
        textIndent: 0,
        content: '"❦"',
        color: 'var(--color-grey)',
        display: 'block',
        textAlign: 'center',
        margin: 'calc(2 * var(--leading)) 0',
        fontSize: 'calc(2 * var(--base))'
      }
    }
  }),
  Question: styled.h5({
    textAlign: 'center',
  }),
  Buttons: styled.div({
    display: 'flex',
    justifyContent: 'center',
    gap: 20,
  }),
  ButtonPrimary: styled.button(button, {
    background: 'var(--color-grey)',
    
    '&:hover': {
      background: 'var(--color-bubblegum)'
    }
  }),
  ButtonSecondary: styled.button(button, {
    boxShadow: 'inset 0 0 0 1px var(--color-bright)',
    background: 'transparent',
  
    '&:hover': {
      background: 'var(--color-bright)',
      color: 'var(--color-grey)'
    }
  })
}
