import styled from '@emotion/styled'
import { ReactNode } from 'react'
import { Footer } from './Footer'
import { Header } from './Header'

const Styled = {
  App: styled.div({
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }),
  Main: styled.main({
    flex: '1 0 auto',
    width: '100%',
  }),
}

export const Layout = ({ children }: { children: ReactNode }) => (
  <Styled.App>
    <Header />
    <Styled.Main>
      {children}
    </Styled.Main>
    <Footer />
  </Styled.App>
)
