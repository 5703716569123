import styled from '@emotion/styled'
import { BarcodeSvg } from './BarcodeSvg'

const Styled = {
  TicketWrapper: styled.div({
    '&:hover > div': {
      transform: 'rotateX(0) rotateY(0) perspective(680px) scale(1.2)'
    }
  }),
  Wrapper: styled.div({
    color: '#472f4c',
    borderRadius: 6,
    fontFamily: 'var(--font-sans)',
    fontSize: 24,
    backgroundColor: '#5f4166',
    opacity: 0.8,
    backgroundSize: '10px 10px',
    backgroundImage: 'repeating-linear-gradient(45deg, rgba(0, 0, 0, .02) 0, rgba(0, 0, 0, .1) 1px, #5f4166 0, #5f4166 50%)',
    margin: '4rem auto',
    boxShadow: '0 4px 16px rgba(0, 0, 0, .2)',
    height: 120,
    maxWidth: 420,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backfaceVisibility: 'visible',
    transform: 'rotate(2deg) rotateX(360deg) rotateY(4deg) perspective(800px)',
    transition: 'transform .5s ease, box-shadow .5s ease',
  
    '&:hover > div': {
      boxShadow: '0 48px 16px rgba(0, 0, 0, .2)',
    },
    
    rect: {
      fill: '#352a3c'
    },
  }),
  Ticket: styled.div({
    flex: 1,
  }),
  Title: styled.div({
    color: '#472f4c',
    padding: 8,
    fontSize: 18,
  }),
  Code: styled.div({
    textAlign: 'center',
    fontFamily: 'var(--font-monospace)',
    color: 'var(--color-bubblegum)',
    flex: 1,
    margin: '0 auto',
    border: '1px solid rgba(0, 0, 0, .1)',
    
    '&::selection': {
      background: 'black'
    }
  }),
  Svg: styled(BarcodeSvg)({
    transform: 'rotate(270deg)',
    transformOrigin: 'right bottom',
    position: 'absolute',
    right: 0,
    width: 126,
    height: 63,
    bottom: '100%',
    margin: '0 0 0 auto',
  }),
  BarCode: styled.div({
    position: 'relative',
    display: 'block',
    width: 126,
    flex: '126px 0 0',
    margin: '0 0 0 auto',
    height: '100%',
    borderLeft: '2px dashed var(--color-dark)',
    background: 'url("image/base64")',
    
    '&:after': {
      content: '""',
      width: 12,
      height: 6,
      backgroundColor: 'var(--color-dark)',
      borderBottomLeftRadius: 110,
      borderBottomRightRadius: 110,
      position: 'absolute',
      top: 0,
      left: -6,
    },
    
    '&:before': {
      content: '""',
      width: 12,
      height: 6,
      backgroundColor: 'var(--color-dark)',
      borderTopLeftRadius: 110,
      borderTopRightRadius: 110,
      position: 'absolute',
      bottom: 0,
      left: -6,
    },
  }),
  UserCode: styled.div({
    fontSize: 10,
    textAlign: 'left',
    padding: 8,
    color: '#472f4c',
    transform: 'rotate(270deg)',
    transformOrigin: 'right top',
    position: 'absolute',
    top: 0,
    right: '100%',
    width: 70,
  }),
  TicketCode: styled(BarcodeSvg)({
    width: 82,
    left: 0,
    margin: '0 auto 0 0',
    height: 40,
  })
}

export const RecruiterFormTicket = ({ code }: { code: string }): JSX.Element => (
  <Styled.TicketWrapper>
    <Styled.Wrapper>
      <Styled.Ticket>
        <Styled.Title>Recruiter Message Pass</Styled.Title>
        <Styled.Code>{code}</Styled.Code>
        <Styled.TicketCode />
      </Styled.Ticket>
      <Styled.BarCode>
        <Styled.UserCode>
          <div>user code:</div>
          <Styled.Code>{code}</Styled.Code>
        </Styled.UserCode>
        <Styled.Svg />
      </Styled.BarCode>
    </Styled.Wrapper>
  </Styled.TicketWrapper>
)
