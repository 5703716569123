import { Styled } from './RecruiterForm.style'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { useState } from 'react'
import { RecruiterFormTicket } from './RecruiterFormTicket'
import { RecruiterSupportBundle } from './RectuiterSupportBundle'

interface RecruiterFormProps {
  step?: string | string[]
}

export const RecruiterForm = ({ step }: RecruiterFormProps) => {
  type Code = Array<{ path: string, score: number }>
  
  const router = useRouter()
  const [currentScore, setCurrentScore] = useState(0)
  
  const [code, setCode] = useState<Code>([])
  const [exclusionaryFactor, setExclusionaryFactor] = useState(false)
  
  const getCode = (c: Code) => 'P' + c.map(({ path, score }) => `${path.charAt(0).toUpperCase()}${score}`).join('')
  
  const jumpTo = (stepId: string, score: number, exclude?: boolean) => () => {
    router.push(`/for-recruiters/${stepId}`)
    if (exclude) {
      setExclusionaryFactor(true)
    }
    setCurrentScore(currentScore + score)
    setCode([...code, {
      path: stepId,
      score,
    }])
  }
  
  const showBundles = false
  
  return (
    <Styled.Wrapper>
      {step === undefined && (
        <>
          <h2>Thank you for being here!</h2>
          <p>
            I know that your job is difficult and I would like to help you by offering this page.
            I am grateful for you reading and going through the questions.
          </p>
          <hr/>
          <Styled.Buttons>
            <Styled.ButtonPrimary onClick={jumpTo('1', 0)}>Great, I want to contact Peter!</Styled.ButtonPrimary>
          </Styled.Buttons>
        </>
      )}
  
      {step === '1' && (
        <>
          <h4>Peter’s not looking to change jobs now.</h4>
          <p>He satisfied with his current position and he is content with the project he is presently working on.
            Nevertheless, he is always open to professional conversation.</p>
          <Styled.Question>Do you still want to contact him?</Styled.Question>
          <Styled.Buttons>
            <Styled.ButtonSecondary onClick={jumpTo('good-bye', 0)}>No, not at this time</Styled.ButtonSecondary>
            <Styled.ButtonPrimary onClick={jumpTo('2', 2)}>Yes, I’m still interested</Styled.ButtonPrimary>
          </Styled.Buttons>
        </>
      )}
  
      {step === '2' && (
        <>
          <h4>Peter is a frontend specialist</h4>
          <p>...with extensive knowledge in his field. He primarily uses React and TypeScript and all the industry-standard tools. He is highly competent in Node.js, with less experience in backend development in general. He has been working as a frontend developer for over ten years; he only considers senior/lead level positions.</p>
          <Styled.Question>Is the position you are representing in line with the above?</Styled.Question>
          <Styled.Buttons>
            <Styled.ButtonSecondary onClick={jumpTo('3', 0, true)}>No it’s not a position like this</Styled.ButtonSecondary>
            <Styled.ButtonSecondary onClick={jumpTo('3', 1)}>It’s similar, but there are some differences</Styled.ButtonSecondary>
            <Styled.ButtonPrimary onClick={jumpTo('3', 2)}>It perfectly suits Peter’s preferences!</Styled.ButtonPrimary>
          </Styled.Buttons>
        </>
      )}
  
      {step === '3' && (
        <>
          <h4>Peter lives in Pécs, Hungary</h4>
          <p>...and works from home. He is satisfied with this situation. He bought a house for his family of five,
            and relocation is not an option for him. </p>
          <Styled.Question>Can this job be performed remotely?</Styled.Question>
          <Styled.Buttons>
            <Styled.ButtonSecondary onClick={jumpTo('4', 0, true)}>No it is an on-site position</Styled.ButtonSecondary>
            <Styled.ButtonSecondary onClick={jumpTo('4', 1)}>Well, it is a hybrid position</Styled.ButtonSecondary>
            <Styled.ButtonPrimary onClick={jumpTo('4', 2)}>Yes, absolutely</Styled.ButtonPrimary>
          </Styled.Buttons>
        </>
      )}
  
      {step === '4' && (
        <>
          <h4>Peter is devoted to the tech industry and product development</h4>
          <p>He enjoys working through well-thought-out technical solutions, and does not like to be required to change focus often.
            That is why he prefers product development over agency work.</p>
          <Styled.Buttons>
            <Styled.ButtonSecondary onClick={jumpTo('5', 1)}>I am not sure if Peter would like this job</Styled.ButtonSecondary>
            <Styled.ButtonPrimary onClick={jumpTo('5', 2)}>This sounds like a good match!</Styled.ButtonPrimary>
          </Styled.Buttons>
        </>
      )}
  
      {step === '5' && (
        <>
          <h4>Peter loves to meet new people, to learn from smart people</h4>
          <p>He prefers not to be evaluated on his professional skills based on simplified formulas,
            so he is unlikely to attend automated coding interviews. So, unfortunately he does not have the time to do one-way video
            interviews or complete development assignments.</p>
          <p>On the other hand, he is more than happy to share his GitHub profile and will be open to answering any questions verbally.</p>
          <p>Does the interview process contain elements like this?</p>
          <Styled.Buttons>
            <Styled.ButtonSecondary onClick={jumpTo('thanks', 0)}>Yes, it does</Styled.ButtonSecondary>
            <Styled.ButtonPrimary onClick={jumpTo('thanks', 2)}>No, it does not</Styled.ButtonPrimary>
          </Styled.Buttons>
        </>
      )}
  
      {step === 'thanks' && (
        <>
          <h4>Thank you again for being here!</h4>
          {(currentScore === 0 || currentScore <= 2 || exclusionaryFactor) && (
            <p>Based on your answers, I would not suggest that you contact Peter. He will most likely not respond.</p>
          )}
          {(currentScore > 2 && currentScore <= 4 && !exclusionaryFactor) && (
            <p>Peter tries to consciously manage his time. As your answers indicate,
              the opportunity you represent does not align with his preferences therefore he may not be able to respond.</p>
          )}
          {(currentScore > 4 && currentScore <= 9 && !exclusionaryFactor) && (
            <p>Based on your responses, Peter will try to answer all relevant inquiries. He will probably answer.</p>
          )}
          {(currentScore === 10 && !exclusionaryFactor) && (
            <p>Peter tries to answer all relevant inquiries. Based on your answers he will probably respond.</p>
          )}
          <hr/>
          <p>If you want to ensure Peter answers, please include this code in your message:</p>
          <RecruiterFormTicket code={getCode(code)}/>
          <Styled.Buttons>
            <a href="https://linkedin.com/in/violapeter">LinkedIn</a>
            <Link href="/">Main page</Link>
          </Styled.Buttons>
  
          {showBundles && (
            <>
              <h3>Let’s get to the point?</h3>
              
              <RecruiterSupportBundle />
            </>
          )}
        </>
      )}
  
      {step === 'good-bye' && (
        <>
          <h4>Okay, that’s fine!</h4>
          {currentScore === 0 && (
            <p>Thank you for considering, maybe later we will work together.</p>
          )}
          <Styled.Question>Until then, have a nice day!</Styled.Question>
  
          <Styled.Buttons>
            <a href="https://linkedin.com/in/violapeter">LinkedIn</a>
            <Link href="/">Main page</Link>
          </Styled.Buttons>
        </>
      )}
    </Styled.Wrapper>
  )
}
